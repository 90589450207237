import { Illustration } from "@clipboard-health/ui-components";
import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { FullScreenDialog } from "@src/appV2/lib";
import { Button } from "@src/appV2/redesign/components/Button";
import { IconButton } from "@src/appV2/redesign/components/IconButton";
import { formatTime } from "@src/appV2/redesign/utils/formatTime";
import { parseISO } from "date-fns";
import { generatePath, useHistory } from "react-router-dom";

import { useIsWorkerShiftsTimekeepingRedesignEnabled } from "../../api/useIsWorkerShiftsRedesignEnabled";
import { SHIFT_DETAILS_FULL_PATH } from "../../path";

interface OnCallOfferBookedConfirmationPropsDialog {
  modalState: UseModalState;
  shiftId: string;
  workplaceName: string;
  workplaceTimezone?: string;
  shiftStart: string;
  onClose: () => void;
}

export function OnCallOfferShiftBookedConfirmationDialog(
  props: OnCallOfferBookedConfirmationPropsDialog
) {
  const { shiftId, workplaceName, workplaceTimezone, shiftStart, modalState, onClose } = props;
  const history = useHistory();
  const isWorkerShiftsTimekeepingPageRedesignEnabled =
    useIsWorkerShiftsTimekeepingRedesignEnabled();

  return (
    <FullScreenDialog modalState={modalState}>
      <Stack
        px={5}
        py={5}
        justifyContent="space-between"
        height="100%"
        sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
      >
        <IconButton
          sx={{ alignSelf: "flex-end" }}
          size="small"
          iconType="close"
          variant="outlined"
          onClick={onClose}
        />
        <Stack alignItems="center" spacing={8} px={5} sx={{ textWrap: "balance" }}>
          <Illustration type="shift-booked" />
          <Text variant="h1" textAlign="center">
            Your shift is booked!
          </Text>
          <Text variant="body1" textAlign="center">
            Please arrive at {workplaceName} for your shift start at{" "}
            {formatTime(parseISO(shiftStart), workplaceTimezone)}
          </Text>
        </Stack>
        <Button
          fullWidth
          size="large"
          onClick={() => {
            const shiftDetailsPath = isWorkerShiftsTimekeepingPageRedesignEnabled
              ? generatePath(SHIFT_DETAILS_FULL_PATH, { shiftId })
              : generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, {
                  shiftId,
                });
            history.replace(shiftDetailsPath);
          }}
        >
          View shift details
        </Button>
      </Stack>
    </FullScreenDialog>
  );
}

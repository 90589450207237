// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { Card, Pill } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import {
  type OnCallShiftOffer,
  type OnCallShiftOfferWorkplace,
} from "@src/appV2/redesign/WorkerShifts/OnCallOffers/types";
import { parseISO } from "date-fns";
import { useEffect, useMemo } from "react";

import { LoadingButton } from "../../components/LoadingButton";
import { useGetRequirementsForShift } from "../../Documents/api/useGetRequirementsForShift";
import { getBlockingWorkerRequirements } from "../../Documents/utils/blockingRequirementsUtils";
import { ShiftDocumentsPill } from "../../Shift/Bookability/Documents/Pill";
import { BookingCardContentWrapper } from "../../Shift/CardContentWrapper";
import { convertOpenShiftToModalShiftData } from "../../Shift/convertOpenShiftToModalShiftData";
import { ShiftCardSummaryInfo } from "../../Shift/Open/CardSummaryInfo";
import { useAutoRefreshOffer } from "../../Shift/Open/useAutoRefreshOffer";
import { ShiftCardPillsWrapper } from "../../Shift/PillsWrapper";
import { BookingCardSummaryWrapper } from "../../Shift/SummaryWrapper";
import { TimeSlotIndicator } from "../../Shift/TimeSlotIndicator";
import { SHIFT_DISCOVERY_ON_CALL_SHIFT_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { useDistanceToWorkplace } from "../../Workplace/useDistanceToWorkplace";
import { OfferExpiresAtPill } from "./OfferExpiresAtPill";

interface OnCallOfferCardProps {
  onCallShiftId: string;
  offeredShift: OnCallShiftOffer;
  offeredShiftWorkplace: OnCallShiftOfferWorkplace;
  shiftBookability: BookabilityStatusItem;
}

export function OnCallOfferCard(props: OnCallOfferCardProps) {
  const { onCallShiftId, offeredShift, offeredShiftWorkplace, shiftBookability } = props;

  const {
    data: { offer },
    isLoading: isLoadingOffer,
    isError: isErrorOffer,
  } = useAutoRefreshOffer({ shiftId: offeredShift.id, onCallShiftId });

  const modalShiftData = useMemo(() => {
    return convertOpenShiftToModalShiftData({
      shift: offeredShift,
      workplace: offeredShiftWorkplace,
      offer,
    });
  }, [offer, offeredShift, offeredShiftWorkplace]);
  const {
    data: shiftRequirements,
    isLoading: isLoadingShiftRequirements,
    isSuccess: isSuccessShiftRequirements,
  } = useGetRequirementsForShift({
    shift: modalShiftData,
    qualification: offeredShift.attributes.qualification,
  });
  const blockingRequirements = useMemo(
    () => getBlockingWorkerRequirements(shiftRequirements),
    [shiftRequirements]
  );

  const { formattedDistance } = useDistanceToWorkplace({
    workplaceGeoLocation: offeredShiftWorkplace?.attributes.location,
  });

  const { navigateToModal, setInitialShiftModalsData, modalRouteParams } =
    useShiftModalsDataContext();
  const shiftIdParameter = modalRouteParams?.shiftId;

  useEffect(() => {
    // if shift id route param is defined, it means we have the modal opened so we should set the data for the appopriate shift
    // as it could've been opened indirectly by opening a deep link
    if (isDefined(shiftIdParameter) && shiftIdParameter === offeredShift.id) {
      setInitialShiftModalsData({
        shift: modalShiftData,
        bookabilityStatus: shiftBookability,
        blockingRequirements,
      });
    }
  }, [
    shiftBookability,
    setInitialShiftModalsData,
    blockingRequirements,
    isSuccessShiftRequirements,
    shiftIdParameter,
    modalShiftData,
    offeredShift.id,
  ]);

  return (
    <Card outlined variant="quaternary" sx={{ opacity: isErrorOffer ? 0.5 : 1 }}>
      <BookingCardContentWrapper>
        <BookingCardSummaryWrapper>
          <TimeSlotIndicator timeSlot={offeredShift.attributes.timeSlot} />
          <ShiftCardSummaryInfo
            dateRange={{
              startDate: parseISO(offeredShift.attributes.start),
              endDate: parseISO(offeredShift.attributes.end),
            }}
            timezone={offeredShiftWorkplace?.attributes.location.timezone ?? ""}
            deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
            durationInHours={offeredShift.attributes.durationInHours}
            workplaceName={offeredShiftWorkplace?.attributes.name ?? ""}
            formattedDistance={formattedDistance}
          >
            {blockingRequirements.length > 0 && (
              <ShiftDocumentsPill missingDocumentsCount={blockingRequirements.length} />
            )}
          </ShiftCardSummaryInfo>
        </BookingCardSummaryWrapper>
        <ShiftCardPillsWrapper justifyContent="center">
          <OfferExpiresAtPill isLoading={isLoadingOffer} expiresAt={offer?.attributes.expiresAt} />
        </ShiftCardPillsWrapper>
        {isErrorOffer ? (
          <Pill
            label="Shift is unavailable"
            color={(theme) => theme.palette.text.secondary}
            iconType="info"
          />
        ) : (
          <LoadingButton
            fullWidth
            isLoading={isLoadingOffer || isLoadingShiftRequirements}
            onClick={() => {
              setInitialShiftModalsData({
                shift: modalShiftData,
                bookabilityStatus: shiftBookability,
                blockingRequirements,
              });
              navigateToModal(SHIFT_DISCOVERY_ON_CALL_SHIFT_MODAL_PATH, {
                onCallShiftId,
                shiftId: offeredShift.id,
              });
            }}
          >
            View details
          </LoadingButton>
        )}
      </BookingCardContentWrapper>
    </Card>
  );
}

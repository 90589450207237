// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import {
  type GetOnCallAvailableShiftsResponse,
  TimeSlot,
} from "@clipboard-health/contract-worker-app-bff";
import { BottomSheet, Card } from "@clipboard-health/ui-components";
import { Title, type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { useRequiresBreakPolicyAcknowledgement } from "@src/appV2/redesign/Shift/Booking/useRequiresBreakPolicyAcknowledgement";
import { OnCallBottomSheetCloseButton } from "@src/appV2/redesign/Shift/BottomSheet/OnCallShift/OnCallBottomSheetCloseButton";
import { WorkerShiftCardFooter } from "@src/appV2/redesign/Shift/Card/Footer";
import { BookingCardContentWrapper } from "@src/appV2/redesign/Shift/CardContentWrapper";
import { ShiftCardSummaryInfo } from "@src/appV2/redesign/Shift/Open/CardSummaryInfo";
import { BookingCardSummaryWrapper } from "@src/appV2/redesign/Shift/SummaryWrapper";
import { TimeSlotIndicator } from "@src/appV2/redesign/Shift/TimeSlotIndicator";
import {
  type ModalShiftData,
  type ModalShiftOfferData,
  type ModalWorkplaceData,
} from "@src/appV2/redesign/Shift/types";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { getShiftWorkDurationInHours } from "@src/appV2/redesign/utils/getShiftWorkDuration";
import { useDistanceToWorkplace } from "@src/appV2/redesign/Workplace/useDistanceToWorkplace";
import { MY_SHIFTS_PATH } from "@src/appV2/Shifts/MyShifts";
import { useQueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

import { GET_ON_CALL_OFFERED_SHIFTS_URL } from "../useOnCallOfferedShifts";
import { AcceptOfferButton } from "./AcceptOfferButton";
import { OnCallOfferShiftBookedConfirmationDialog } from "./ShiftBookedConfirmationDialog";

interface AcceptOfferBottomSheetProps {
  modalState: UseModalState;
  onCallShiftId: string;
  shift?: ModalShiftData;
  offer?: ModalShiftOfferData;
  workplace?: ModalWorkplaceData;
}

export function AcceptOfferBottomSheet(props: AcceptOfferBottomSheetProps) {
  const { onCallShiftId, shift, offer, workplace, modalState } = props;

  const { navigateToModal } = useShiftModalsDataContext();

  const { formattedDistance } = useDistanceToWorkplace({
    workplaceGeoLocation: workplace?.attributes.location,
  });

  const durationInHoursWithBreak = getShiftWorkDurationInHours(
    { start: shift?.attributes.start ?? "", end: shift?.attributes.end ?? "" },
    workplace?.attributes.requiresLunchBreak ?? false
  );

  const bookConfirmationModalState = useModalState();
  const history = useHistory();

  // Pre-fetch break policy acknowledgement for the shift,
  // so that it's ready when the user clicks the shift card.
  // No need to fetch if there's no offer, as it's not bookable.
  useRequiresBreakPolicyAcknowledgement({
    workplaceId: workplace?.id,
    enabled: isDefined(offer),
  });

  const queryClient = useQueryClient();

  return (
    <BottomSheet modalState={modalState}>
      <Stack
        spacing={10}
        sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
        py={8}
        px={7}
        position="relative"
      >
        <OnCallBottomSheetCloseButton onClick={modalState.closeModal} />
        <Stack alignItems="center" spacing={5}>
          <Title component="h3" py={3}>
            Confirm offer
          </Title>
          <Card outlined variant="quaternary" sx={{ width: "100%" }}>
            <BookingCardContentWrapper>
              <BookingCardSummaryWrapper>
                <TimeSlotIndicator timeSlot={shift?.attributes.timeSlot ?? TimeSlot.AM} />
                <ShiftCardSummaryInfo
                  dateRange={{
                    startDate: parseISO(shift?.attributes.start ?? ""),
                    endDate: parseISO(shift?.attributes.end ?? ""),
                  }}
                  timezone={workplace?.attributes.location.timezone ?? ""}
                  deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
                  durationInHours={durationInHoursWithBreak}
                  workplaceName={workplace?.attributes.name ?? ""}
                  formattedDistance={formattedDistance}
                />
              </BookingCardSummaryWrapper>
              <WorkerShiftCardFooter
                hourlyPay={offer?.attributes.formattedHourlyPay}
                totalPay={offer?.attributes.formattedTotalPay}
                qualificationText={shift?.attributes.qualification ?? ""}
              />
            </BookingCardContentWrapper>
          </Card>
        </Stack>
        <AcceptOfferButton
          shiftId={shift?.id ?? ""}
          shiftOfferId={offer?.id}
          workplaceId={workplace?.id}
          navigateToModal={navigateToModal}
          onCallShiftId={onCallShiftId}
          onBook={() => {
            queryClient.setQueryData(
              [GET_ON_CALL_OFFERED_SHIFTS_URL.replace(":onCallShiftId", onCallShiftId)],
              { data: [], included: [] } as GetOnCallAvailableShiftsResponse
            );
            bookConfirmationModalState.openModal();
          }}
        />
        <OnCallOfferShiftBookedConfirmationDialog
          shiftId={shift?.id ?? ""}
          shiftStart={shift?.attributes.start ?? ""}
          workplaceName={workplace?.attributes.name ?? ""}
          workplaceTimezone={workplace?.attributes.location.timezone}
          modalState={bookConfirmationModalState}
          onClose={() => {
            history.push(`${RootPaths.HOME}/${MY_SHIFTS_PATH}`);
          }}
        />
      </Stack>
    </BottomSheet>
  );
}

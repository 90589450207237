import {
  type UpdateOnCallShiftRequestBody,
  type UpdateOnCallShiftResponse,
} from "@clipboard-health/contract-worker-app-bff";
import { type ApiResponse, patch } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

import { useGetWorkerShifts } from "../api/useGetWorkerShifts";

export type EndOnCallShiftRequestBody = UpdateOnCallShiftRequestBody;
export const endOnCallShiftRequestBodySchema = z.custom<EndOnCallShiftRequestBody>();

export type EndOnCallShiftResponse = UpdateOnCallShiftResponse;
export const endOnCallShiftResponseSchema = z.custom<UpdateOnCallShiftResponse>();

export const END_ON_CALL_SHIFT_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/on-call-shifts/:onCallShiftId`;

export function useEndOnCallShift(
  onCallShiftId: string,
  options: UseMutationOptions<ApiResponse<EndOnCallShiftResponse>> = {}
) {
  const worker = useDefinedWorker();
  const { remove: removeCachedWorkerShifts } = useGetWorkerShifts({ worker });

  return useMutation({
    mutationFn: async () => {
      return await patch({
        url: END_ON_CALL_SHIFT_URL.replace(":onCallShiftId", onCallShiftId),
        data: {
          data: {
            id: onCallShiftId,
            type: "on-call-shift",
            attributes: {
              reason: "",
              status: "REJECTED",
            },
          },
        },
        requestSchema: endOnCallShiftRequestBodySchema,
        responseSchema: endOnCallShiftResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.END_ON_CALL_SHIFT_FAILURE,
    },
    onSuccess: () => {
      removeCachedWorkerShifts();
    },
    ...options,
  });
}

import { Pill, PillSkeleton } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { differenceInHours, differenceInMinutes, parseISO } from "date-fns";
import pluralize from "pluralize";

interface OfferExpiresAtPillProps {
  isLoading?: boolean;
  expiresAt?: string;
}
export function OfferExpiresAtPill(props: OfferExpiresAtPillProps) {
  const { isLoading, expiresAt } = props;

  const hoursLeftUntilOfferExpiry = isDefined(expiresAt)
    ? differenceInHours(parseISO(expiresAt), new Date())
    : 0;
  const minutesLeftUntilOfferExpiry = isDefined(expiresAt)
    ? differenceInMinutes(parseISO(expiresAt), new Date())
    : 0;
  const timeUnitsLeftUntilOfferExpiryCount =
    hoursLeftUntilOfferExpiry < 1 ? minutesLeftUntilOfferExpiry : hoursLeftUntilOfferExpiry;
  const timeUnitsLeftUntilOfferExpiryText = pluralize(
    hoursLeftUntilOfferExpiry < 1 ? "minute" : "hour",
    timeUnitsLeftUntilOfferExpiryCount
  );
  const formattedTimeLeftUntilOfferExpiry = isDefined(expiresAt)
    ? `${timeUnitsLeftUntilOfferExpiryCount} ${timeUnitsLeftUntilOfferExpiryText}`
    : undefined;

  return (
    <>
      {isLoading && <PillSkeleton size="small" />}
      {!isLoading && isDefined(expiresAt) && (
        <Pill
          variant="filled"
          size="small"
          color={(theme) => theme.palette.intent?.error.text}
          backgroundColor={(theme) => theme.palette.intent?.error.background}
          label={`Offer expires in ${formattedTimeLeftUntilOfferExpiry}`}
        />
      )}
    </>
  );
}

import { Illustration } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

export function OnCallOffersErrorPageState() {
  return (
    <Stack alignItems="center" justifyContent="center" spacing={8} flexGrow={1}>
      <Illustration type="browse-shifts" />
      <Text>Could not load on call shifts</Text>
    </Stack>
  );
}

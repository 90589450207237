import { Skeleton, Stack } from "@mui/material";

export function OnCallOffersLoadingPageState() {
  return (
    <Stack>
      <Skeleton variant="text" width="100%" height="10rem" />
      <Skeleton variant="text" width="100%" height="10rem" />
      <Skeleton variant="text" width="100%" height="10rem" />
    </Stack>
  );
}

import { type GetOnCallAvailableShiftsResponse } from "@clipboard-health/contract-worker-app-bff";
import { BottomSheet, Illustration } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { MY_SHIFTS_PATH } from "@src/appV2/Shifts/MyShifts";
import { useQueryClient } from "@tanstack/react-query";
import { useHistory, useLocation } from "react-router-dom";

import { LoadingButton } from "../../components/LoadingButton";
import { OnCallBottomSheetCloseButton } from "../../Shift/BottomSheet/OnCallShift/OnCallBottomSheetCloseButton";
import { useEndOnCallShift } from "./useEndOnCallShift";
import { GET_ON_CALL_OFFERED_SHIFTS_URL } from "./useOnCallOfferedShifts";

interface EndOnCallBottomSheetProps {
  onCallShiftId: string;
  modalState: UseModalState;
}

export function EndOnCallBottomSheet(props: EndOnCallBottomSheetProps) {
  const { onCallShiftId, modalState } = props;

  const { showErrorToast } = useToast();
  const { mutateAsync: endOnCall, isLoading } = useEndOnCallShift(onCallShiftId, {
    onError: () => {
      showErrorToast("Error ending on call");
    },
  });
  const history = useHistory();
  const location = useLocation();

  const queryClient = useQueryClient();

  return (
    <BottomSheet modalState={modalState}>
      <Stack
        spacing={10}
        sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
        py={8}
        px={10}
      >
        <OnCallBottomSheetCloseButton onClick={modalState.closeModal} />

        <Stack
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          spacing={5}
          px={5}
          sx={{ textWrap: "balance" }}
        >
          <Illustration type="info" />
          <Title component="h4" py={3} sx={{ textWrap: "balance" }}>
            Are you sure you want to end your on-call period?
          </Title>
          <Text variant="body2">This will reject all offers and you will not receive any pay.</Text>
        </Stack>
        <LoadingButton
          isLoading={isLoading}
          size="large"
          onClick={async () => {
            try {
              await endOnCall();
              logEvent(APP_V2_USER_EVENTS.ON_CALL_SHIFT_ALL_OFFERS_REJECTED, { onCallShiftId });
              queryClient.setQueryData(
                [GET_ON_CALL_OFFERED_SHIFTS_URL.replace(":onCallShiftId", onCallShiftId)],
                { data: [], included: [] } as GetOnCallAvailableShiftsResponse
              );

              // no key means we can't go back
              if (isDefined(location.key)) {
                history.goBack();
              } else {
                history.push(`${RootPaths.HOME}/${MY_SHIFTS_PATH}`);
              }
            } catch {}
          }}
        >
          Yes, end on call
        </LoadingButton>
      </Stack>
    </BottomSheet>
  );
}

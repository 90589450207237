// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { CbhIcon } from "@clipboard-health/ui-components";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useIsDeviceLocationPermissionGranted } from "@src/appV2/Location/deviceLocation/geoLocation";
import { useGetBookabilityStatusForShifts } from "@src/appV2/OpenShifts/ShiftAction/api/useGetBookabilityStatusForShifts";
import { MY_SHIFTS_PATH } from "@src/appV2/Shifts/MyShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { generatePath, useParams } from "react-router-dom";

import { Button } from "../../components/Button";
import { PageWrapper } from "../../components/PageWrapper";
import { StickyScrollPageHeader } from "../../components/StickyScrollPageHeader";
import { LocationPermissionsBottomSheet } from "../../Shift/Bookability/LocationPermissionsBottomSheet";
import { SHIFT_DISCOVERY_ON_CALL_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { PageContent } from "../components/PageContent";
import { OnCallOffersEmptyPageState } from "./EmptyPageState";
import { EndOnCallBottomSheet } from "./EndOnCallBottomSheet";
import { OnCallOffersErrorPageState } from "./ErrorPageState";
import { OnCallOffersLoadingPageState } from "./LoadingPageState";
import { OnCallOfferCard } from "./OfferCard";
import { invalidateOnCallOfferedShifts, useOnCallOfferedShifts } from "./useOnCallOfferedShifts";

export function OnCallOffersPage() {
  const queryClient = useQueryClient();
  const { onCallShiftId } = useParams<{ onCallShiftId: string }>();
  const worker = useDefinedWorker();

  const { data, isLoading, isError, isSuccess } = useOnCallOfferedShifts(onCallShiftId, {
    staleTime: 10_000,
    refetchInterval: 2 * 60_000,
  });
  const { setBaseNavigationPathOverride } = useShiftModalsDataContext();

  const showEmptyState = isSuccess && data.data.length === 0;
  const scrollRef = useRef<HTMLDivElement>(null);

  const {
    data: isDeviceLocationPermissionGranted,
    isSuccess: isDeviceLocationPermissionGrantedSuccess,
    isLoading: isDeviceLocationPermissionGrantedLoading,
  } = useIsDeviceLocationPermissionGranted();

  const locationPermissionsModalState = useModalState();

  const geolocationIsUnavailable =
    isDeviceLocationPermissionGrantedSuccess &&
    isDeviceLocationPermissionGranted === false &&
    isCapacitorPlatform();

  useEffect(() => {
    if (geolocationIsUnavailable) {
      locationPermissionsModalState.openModal();
    }
  }, [geolocationIsUnavailable, locationPermissionsModalState]);

  const { shiftBookabilityById } = useGetBookabilityStatusForShifts({
    shiftIds: data?.data.map((shift) => shift.id) ?? [],
    agentId: worker.userId,
    onCallShiftId,
  });
  const shiftsBookabilityMap = new Map(Object.entries(shiftBookabilityById));

  const rejectAllOffersModalState = useModalState();

  useEffect(() => {
    setBaseNavigationPathOverride(generatePath(SHIFT_DISCOVERY_ON_CALL_PATH, { onCallShiftId }));
    return () => {
      setBaseNavigationPathOverride(undefined);
    };
  }, [onCallShiftId, setBaseNavigationPathOverride]);

  return (
    <Stack
      sx={{
        overflow: "hidden",
        position: "relative",
      }}
    >
      <PageWrapper variant="tertiary">
        <StickyScrollPageHeader
          title="On call shifts"
          scrollContainerRef={scrollRef}
          headerVariant="tertiary"
          fallbackGoBackPath={`${RootPaths.HOME}/${MY_SHIFTS_PATH}`}
        />
        <EndOnCallBottomSheet
          modalState={rejectAllOffersModalState}
          onCallShiftId={onCallShiftId}
        />

        <PullToRefresh
          refreshHeight={96}
          onRefresh={async () => {
            await invalidateOnCallOfferedShifts(queryClient, onCallShiftId);
          }}
        />

        <PageContent ref={scrollRef}>
          {showEmptyState && <OnCallOffersEmptyPageState />}
          {isError && <OnCallOffersErrorPageState />}
          {(isLoading || isDeviceLocationPermissionGrantedLoading) && (
            <OnCallOffersLoadingPageState />
          )}
          {isSuccess && (
            <>
              <Box sx={{ marginY: 8 }}>
                <Stack spacing={4}>
                  {data?.data.map((offer) => (
                    <OnCallOfferCard
                      key={offer.id}
                      offeredShift={offer}
                      shiftBookability={shiftsBookabilityMap.get(offer.id)!}
                      offeredShiftWorkplace={
                        data.included.find(
                          (included) => included?.id === offer.relationships.workplace.data.id
                        )!
                      }
                      onCallShiftId={onCallShiftId}
                    />
                  ))}
                </Stack>
              </Box>
              {data.data.length > 0 && (
                <Stack
                  position="absolute"
                  bottom={(theme) => theme.spacing(2)}
                  left={0}
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={rejectAllOffersModalState.openModal}
                  >
                    <Stack direction="row" alignItems="center" spacing={3}>
                      <CbhIcon type="close" color={(theme) => theme.palette.intent?.error.icon} />
                      <Text>End on call</Text>
                    </Stack>
                  </Button>
                </Stack>
              )}
            </>
          )}
        </PageContent>

        <LocationPermissionsBottomSheet
          modalState={locationPermissionsModalState}
          isNotNowButtonVisible={false}
        />
      </PageWrapper>
    </Stack>
  );
}

import { BottomSheet } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined, isNil } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";
import { ShiftBottomSheetHeader } from "@src/appV2/redesign/Shift/BottomSheet/Header";
import { ShiftBottomSheetContent } from "@src/appV2/redesign/Shift/BottomSheet/SheetContent";
import { ShiftBottomSheetSkeleton } from "@src/appV2/redesign/Shift/BottomSheet/SheetSkeleton";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { useParams } from "react-router-dom";

import { type OnCallShiftOfferDetailsModalRoutePathParams } from "../types";
import { OnCallShiftOfferDetailsBottomSheetFooter } from "./BottomSheetFooter";

interface OnCallShiftOfferDetailsBottomSheetProps {
  modalState: UseModalState;
}

export function OnCallShiftOfferDetailsBottomSheet(props: OnCallShiftOfferDetailsBottomSheetProps) {
  const { modalState } = props;
  const { onCallShiftId, shiftId } = useParams<OnCallShiftOfferDetailsModalRoutePathParams>();
  const {
    shift,
    bookabilityStatus,
    hasHoursRestrictionConflict,
    blockingRequirements,
    hasBlockingRequirements,
  } = useShiftModalsDataContext();

  const isLoading = isNil(blockingRequirements) || !isDefined(bookabilityStatus);

  useLogEffect(
    APP_V2_USER_EVENTS.SHIFT_DETAILS_VIEWED,
    { shiftId, onCallShiftId },
    { enabled: modalState.modalIsOpen }
  );

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <OnCallShiftOfferDetailsBottomSheetFooter
          offer={shift?.offer}
          shift={shift}
          workplace={shift?.workplace}
          bookabilityStatus={bookabilityStatus}
          onCallShiftId={onCallShiftId}
        />
      }
      onClose={() => {
        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CLOSED, { shiftId: shift?.id });
      }}
    >
      <ShiftBottomSheetHeader onClose={modalState.closeModal} />

      {isDefined(shift) && isDefined(shift.workplace) && !isLoading && (
        <ShiftBottomSheetContent
          shiftId={shift.id}
          shiftType={shift.attributes.type}
          shiftTimeSlot={shift.attributes.timeSlot}
          shiftStartIso={shift.attributes.start}
          shiftEndIso={shift.attributes.end}
          shiftDurationInHours={shift.attributes.durationInHours}
          workplace={shift.workplace}
          bookabilityStatus={bookabilityStatus}
          hasHoursRestrictionConflict={hasHoursRestrictionConflict}
          hasBlockingRequirements={hasBlockingRequirements}
          shiftFailedLoading={hasUnmetBookabilityCriteria(bookabilityStatus, [
            BookabilityUnmetCriteria.SHIFT_ALREADY_GRABBED,
            BookabilityUnmetCriteria.SHIFT_DELETED,
          ])}
        />
      )}

      {isLoading && <ShiftBottomSheetSkeleton />}
    </BottomSheet>
  );
}

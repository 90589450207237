import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { LoadingButton } from "@src/appV2/redesign/components/LoadingButton";
import { PreBookingCheck, useBookShift } from "@src/appV2/redesign/Shift/Booking/useBookShift";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { useGetWorkerShifts } from "../../api/useGetWorkerShifts";

interface AcceptOfferButtonProps {
  onCallShiftId: string;
  shiftId: string;
  shiftOfferId?: string;
  workplaceId?: string;
  onBook: () => void;
  disabled?: boolean;
  navigateToModal: (path: string, params: any) => any;
}

export function AcceptOfferButton(props: AcceptOfferButtonProps) {
  const { onBook, onCallShiftId, shiftId, shiftOfferId, workplaceId, disabled, navigateToModal } =
    props;

  const { attemptBookingShift, isBooking, isLoading } = useBookShift({
    shiftId,
    shiftOfferId,
    onCallShiftId,
    workplaceId,
    navigateToModal,
    onBook,
  });
  const worker = useDefinedWorker();
  const { remove: removeCachedWorkerShifts } = useGetWorkerShifts({ worker });

  return (
    <LoadingButton
      size="large"
      variant="contained"
      isLoading={isLoading || isBooking}
      disabled={disabled}
      sx={{ minWidth: "30%" }}
      onClick={async () => {
        // we want to skip the check which shows a booking confirmation modal, because we have a different modal for on call shifts
        await attemptBookingShift({
          skipChecks: [PreBookingCheck.BOOKING_CONFIRMATION],
        });
        logEvent(APP_V2_USER_EVENTS.ON_CALL_SHIFT_OFFER_ACCEPTED, {
          shiftId,
          shiftOfferId,
          onCallShiftId,
        });
        removeCachedWorkerShifts();
      }}
    >
      Accept this shift
    </LoadingButton>
  );
}

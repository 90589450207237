import { PageHeaderWrapper } from "@clipboard-health/ui-components";
import { Title, useModalState } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { Box, Stack, ThemeProvider } from "@mui/material";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useCurrentDate } from "@src/appV2/lib/utils/useCurrentDate";
import { useIsDeviceLocationPermissionGranted } from "@src/appV2/Location/deviceLocation/geoLocation";
import { useEffect } from "react";

import { PageWrapper } from "../components/PageWrapper";
import { LocationPermissionsBottomSheet } from "../Shift/Bookability/LocationPermissionsBottomSheet";
import { OnCallStatusCard } from "../Shift/WorkerShift/OnCall/OnCallStatusCard";
import { useIsWorkerShiftsTimekeepingRedesignEnabled } from "./api/useIsWorkerShiftsRedesignEnabled";
import { BookingsContainer } from "./components/BookingsContainer";
import { WorkerShiftsCalloutsContainer } from "./components/CalloutsContainer";
import { PageContent } from "./components/PageContent";
import { useWorkerBookingsData } from "./hooks/useWorkerBookingsData";

export function WorkerShiftsPage() {
  const {
    data: isDeviceLocationPermissionGranted,
    isSuccess: isDeviceLocationPermissionGrantedSuccess,
    isLoading: isDeviceLocationPermissionGrantedLoading,
  } = useIsDeviceLocationPermissionGranted();

  const {
    // Booking data
    scheduledBookings,
    highlightedBooking,
    highlightedShiftPayBreakdown,
    hasUpcomingShifts,

    // Loading states
    isLoading: isLoadingBookings,
    isLoadingShiftInvites,
    isLoadingUnverifiedShifts,
    isLoadingNegotiations,
    isLoadingSentHome,
    isLoadingExtraWorked,

    // Callout data
    shiftInvites,
    unverifiedShifts,
    openNegotiationsCount,
    sentHomeRequestsData,
    extraWorkedTimeRequestsData,

    // UI state
    showEmptyShiftState,

    // Actions
    refreshAllData,
  } = useWorkerBookingsData();

  const currentDate = useCurrentDate(1000);
  const locationPermissionsModalState = useModalState();
  const isWorkerShiftsTimekeepingPageRedesignEnabled =
    useIsWorkerShiftsTimekeepingRedesignEnabled();
  const geolocationIsUnavailable =
    isDeviceLocationPermissionGrantedSuccess &&
    isDeviceLocationPermissionGranted === false &&
    isCapacitorPlatform();

  useEffect(() => {
    if (geolocationIsUnavailable && hasUpcomingShifts) {
      locationPermissionsModalState.openModal();
    }
  }, [geolocationIsUnavailable, locationPermissionsModalState, hasUpcomingShifts]);

  const isLoading = isLoadingBookings || isDeviceLocationPermissionGrantedLoading;

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper variant="tertiary">
        <PageHeaderWrapper variant="tertiary">
          <Stack spacing={4} direction="column" sx={{ paddingTop: 5 }}>
            <Box sx={{ paddingX: 4 }}>
              <WorkerShiftsCalloutsContainer
                shiftInviteCount={shiftInvites?.data.length ?? 0}
                unverifiedShiftsCount={unverifiedShifts.length}
                activeNegotiationsCount={openNegotiationsCount?.totalNegotiatingShiftsCount ?? 0}
                facilityProposalsCount={openNegotiationsCount?.negotiationsWaitingOnWorker ?? 0}
                extraWorkedRequestsCount={(extraWorkedTimeRequestsData?.data ?? []).length}
                sentHomeRequestsCount={(sentHomeRequestsData ?? []).length}
                isLoadingShiftInvites={isLoadingShiftInvites}
                isLoadingUnverifiedShifts={isLoadingUnverifiedShifts}
                isLoadingNegotiations={isLoadingNegotiations}
                isLoadingSentHome={isLoadingSentHome}
                isLoadingExtraWorked={isLoadingExtraWorked}
              />
            </Box>
            <Title semibold component="h5" variant="h5" sx={{ paddingX: 5 }}>
              My Bookings
            </Title>
          </Stack>
        </PageHeaderWrapper>

        <PullToRefresh refreshHeight={96} onRefresh={refreshAllData} />

        <PageContent>
          <OnCallStatusCard bookings={scheduledBookings} />
          <BookingsContainer
            isLoading={isLoading}
            bookings={scheduledBookings}
            highlightedBooking={highlightedBooking}
            shiftPayBreakdown={highlightedShiftPayBreakdown}
            currentDate={currentDate}
            isWorkerShiftsTimekeepingPageRedesignEnabled={
              isWorkerShiftsTimekeepingPageRedesignEnabled
            }
            showEmptyShiftState={showEmptyShiftState}
          />
        </PageContent>

        <LocationPermissionsBottomSheet
          modalState={locationPermissionsModalState}
          isNotNowButtonVisible={false}
        />
      </PageWrapper>
    </ThemeProvider>
  );
}

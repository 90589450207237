import {
  type GetOnCallAvailableShiftsResponse,
  getOnCallAvailableShiftsResponse,
} from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type QueryClient } from "@tanstack/react-query";

export const GET_ON_CALL_OFFERED_SHIFTS_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/on-call-shifts/:onCallShiftId/shifts`;

export function useOnCallOfferedShifts(
  onCallShiftId: string,
  options: UseGetQueryOptions<GetOnCallAvailableShiftsResponse> = {}
) {
  return useGetQuery({
    url: GET_ON_CALL_OFFERED_SHIFTS_URL.replace(":onCallShiftId", onCallShiftId),
    queryParams: {
      include: ["workplace"],
    },
    responseSchema: getOnCallAvailableShiftsResponse,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_ON_CALL_SHIFTS_FAILURE,
      userErrorMessage: "Error loading on call shifts",
    },
    useErrorBoundary: false,
    ...options,
  });
}

export async function invalidateOnCallOfferedShifts(
  queryClient: QueryClient,
  onCallShiftId?: string
) {
  if (isDefined(onCallShiftId)) {
    await queryClient.invalidateQueries({
      queryKey: [GET_ON_CALL_OFFERED_SHIFTS_URL.replace(":onCallShiftId", onCallShiftId)],
    });
  }

  await queryClient.invalidateQueries({
    predicate: (query) => {
      return (
        query?.queryKey?.[0]
          ?.toString()
          .includes(GET_ON_CALL_OFFERED_SHIFTS_URL.split(":onCallShiftId")[0]) ?? false
      );
    },
  });
}

import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import {
  BookabilityDecision,
  type BookabilityStatusItem,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { LoadingButton } from "@src/appV2/redesign/components/LoadingButton";
import { HourlyPayLabel } from "@src/appV2/redesign/Pay/HourlyPayLabel";
import { TotalPayLabel } from "@src/appV2/redesign/Pay/TotalPayLabel";
import { useRequiresBreakPolicyAcknowledgement } from "@src/appV2/redesign/Shift/Booking/useRequiresBreakPolicyAcknowledgement";
import { BreakConfirmationBottomSheet } from "@src/appV2/redesign/Shift/BreakConfirmationBottomSheet/BreakConfirmationBottomSheet";
import {
  type ModalShiftData,
  type ModalShiftOfferData,
  type ModalWorkplaceData,
} from "@src/appV2/redesign/Shift/types";

import { AcceptOfferBottomSheet } from "./AcceptOfferBottomSheet";

interface ShiftBottomSheetFooterProps {
  onCallShiftId: string;
  shift?: ModalShiftData;
  workplace?: ModalWorkplaceData;
  offer?: ModalShiftOfferData;
  bookabilityStatus?: BookabilityStatusItem;
}

export function OnCallShiftOfferDetailsBottomSheetFooter(props: ShiftBottomSheetFooterProps) {
  const { onCallShiftId, shift, workplace, offer, bookabilityStatus } = props;

  const isTestAccount = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_DISABLED_TEST_ACCOUNT,
  ]);

  const acceptOfferModalState = useModalState();

  const isBookable =
    bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.ALLOWED;
  const isInstantBookDisabled = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED,
  ]);
  const acceptButtonDisabled = !isBookable || isInstantBookDisabled;

  const {
    requiresBreakPolicyAcknowledgement,
    breakPolicyNoteId,
    isLoading: isLoadingRequiresBreakPolicyAcknowledgement,
  } = useRequiresBreakPolicyAcknowledgement({
    workplaceId: shift?.workplace.id,
  });

  const breakPolicyModalState = useModalState();

  return (
    <DialogFooter withShadow orientation="vertical">
      <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
        <Stack spacing={3} sx={{ paddingLeft: 5 }}>
          {isDefined(offer) && (
            <>
              <HourlyPayLabel
                size="large"
                hourlyPay={offer.attributes.formattedHourlyPay}
                isLoading={!isDefined(offer)}
              />
              <TotalPayLabel
                totalPay={offer.attributes.formattedTotalPay}
                isLoading={!isDefined(offer)}
              />
            </>
          )}
        </Stack>

        {isTestAccount ? (
          <Button variant="contained" size="large">
            Disabled for test accounts
          </Button>
        ) : (
          <LoadingButton
            size="large"
            variant="contained"
            isLoading={!isDefined(shift) || isLoadingRequiresBreakPolicyAcknowledgement}
            disabled={acceptButtonDisabled}
            sx={{ minWidth: "30%" }}
            onClick={() => {
              if (requiresBreakPolicyAcknowledgement && isDefined(breakPolicyNoteId)) {
                breakPolicyModalState.openModal();
              } else {
                acceptOfferModalState.openModal();
              }
            }}
          >
            {acceptButtonDisabled ? "Can't accept" : "Accept this shift"}
          </LoadingButton>
        )}
        <BreakConfirmationBottomSheet
          shiftId={shift?.id ?? ""}
          workplaceId={workplace?.id}
          breakPolicyNoteId={breakPolicyNoteId!}
          modalState={breakPolicyModalState}
          onAcknowledge={async () => {
            breakPolicyModalState.closeModal();
            acceptOfferModalState.openModal();
          }}
        />
        <AcceptOfferBottomSheet
          modalState={acceptOfferModalState}
          shift={shift}
          offer={offer}
          workplace={workplace}
          onCallShiftId={onCallShiftId}
        />
      </Stack>
    </DialogFooter>
  );
}
